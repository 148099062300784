_cruxUtils._cruxLocale = {
	"crm.criteria.secondaryfield.valid.check" : "\u0915\u0943\u092a\u092f\u093e {0} \u0915\u0947 \u0932\u093f\u090f \u092e\u0948\u091a\u093f\u0902\u0917 \u092e\u093e\u0928 \u092a\u094d\u0930\u0926\u093e\u0928 \u0915\u0930\u0947\u0902",
	"crm.label.field":"\u092b\u0940\u0932\u094d\u0921",//no i18n
"crm.label.value":"\u092e\u0942\u0932\u094d\u092f",//no i18n
	"sentiment.criteria.wrongcriteria":"\u092e\u093e\u0928\u0926\u0902\u0921 \u092e\u0942\u0932\u094d\u092f {0} \u0938\u0947 \u0905\u0927\u093f\u0915 \u0928\u0939\u0940\u0902 \u0939\u094b \u0938\u0915\u0924\u093e",//no i18n
"crm.mb.field.common.splc":"\u0938\u094d\u092a\u0947\u0936\u0932 \u0915\u0930\u0947\u0915\u094d\u091f\u0930 \u0915\u0940 \u0905\u0928\u0941\u092e\u0924\u093f \u0928\u0939\u0940\u0902 \u0939\u0948. \u090f\u0915 \u092e\u093e\u0928\u094d\u092f \u092e\u093e\u0928 \u0926\u0930\u094d\u091c \u0915\u0930\u0947\u0902",//no i18n
	"crm.label.field.plural":"\u092b\u0940\u0932\u094d\u0921\u094d\u0938",//no i18n
	"crm.label.in.minutes":"{0} (\u092e\u093f\u0928\u091f\u094b\u0902 \u092e\u0947\u0902)",//no i18n
	"crm.security.roles.list":"\u0930\u094b\u0932\u094d\u0938 \u0932\u093f\u0938\u094d\u091f",//no i18n
"crm.security.roles.lookup.info":"\u0915\u0943\u092a\u092f\u093e \u0928\u093f\u092e\u094d\u0928\u0932\u093f\u0916\u093f\u0924 \u0932\u093f\u0938\u094d\u091f \u0938\u0947 \u0915\u094b\u0908 \u090f\u0915 \u0930\u094b\u0932 \u091a\u0941\u0928\u0947\u0902",//no i18n
"crm.territory.addterritory":"\u0915\u094d\u0937\u0947\u0924\u094d\u0930 \u091c\u094b\u0921\u093c\u0947\u0902",//no i18n
"crm.title.edit.territory":"\u091f\u0947\u0930\u093f\u091f\u0930\u0940 \u0915\u094b \u0938\u0902\u092a\u093e\u0926\u093f\u0924 \u0915\u0930\u0947\u0902",//no i18n
"crm.territory.title.assign.territories":"\u091f\u0947\u0930\u093f\u091f\u0930\u0940 \u0906\u0935\u0902\u091f\u093f\u0924 \u0915\u0930\u0947\u0902",//no i18n
	"crm.label.context.help":"\u0939\u0947\u0932\u094d\u092a",//no i18n
	"crm.label.from":"\u0938\u0947",//no i18n
"crm.label.to":"\u0915\u094B",//no i18n
	"workflow.option.webhookFailure.fromDate":"\u0924\u093F\u0925\u093F \u0938\u0947",//no i18n
"workflow.option.webhookFailure.toDate":"\u0924\u093F\u0925\u093F \u0924\u0915",//no i18n
"crm.custom.field.less.than.equalto":"{0} {1} \u0938\u0947 \u0915\u092E \u092F\u093E \u0909\u0938\u0915\u0947 \u092C\u0930\u093E\u092C\u0930 \u0939\u094B\u0928\u093E \u091A\u093E\u0939\u093F\u090F.",//no i18n
	"crm.template.listview.search.no.results":"\u0915\u094b\u0908 \u092a\u0930\u093f\u0923\u093e\u092e \u0928\u0939\u0940\u0902 \u092e\u093f\u0932\u093e",//No I18n
	"crm.wf.usage.date.criteria.error.msg":"\u2018\u092a\u094d\u0930\u0947\u0937\u0915\u2019 \u0924\u093e\u0930\u0940\u0916 \u2018\u092a\u094d\u0930\u0947\u0937\u093f\u0924\u093f\u2019 \u0924\u093e\u0930\u0940\u0916 \u0938\u0947 \u0905\u0927\u093f\u0915 \u0928\u0939\u0940\u0902 \u0939\u094b \u0938\u0915\u0924\u0940\u0964",//no i18n
	"crm.label.tag.new":"\u0928\u092f\u093e \u091f\u0948\u0917",//No I18n
	"crm.label.enter.tag":"\u091f\u0948\u0917\u094d\u0938 \u0926\u0930\u094d\u091c \u0915\u0930\u0947\u0902",//No I18n
	"crux.comboBox.max.limit":"\u0906\u092a {0} {1} \u0938\u0947 \u0905\u0927\u093f\u0915 \u0915\u093e \u091a\u092f\u0928 \u0928\u0939\u0940\u0902 \u0915\u0930 \u0938\u0915\u0924\u0947.",//NO I18n
	"Administrator":"\u090F\u0921\u092E\u093F\u0928\u093F\u0938\u094D\u091F\u094D\u0930\u0947\u091F\u0930",//No I18n
	"Standard":"\u0938\u094D\u091F\u0947\u0902\u0921\u0930\u094D\u0921",//No I18n
	"crm.button.add":"\u091C\u094B\u0921\u093C\u0947\u0902",//NO I18n
	"crm.label.users":"\u0909\u092A\u092F\u094B\u0917\u0915\u0930\u094D\u0924\u093E", //NO I18n
  "crm.workflow.alert.roles":"\u0930\u094B\u0932\u094D\u0938 ", //NO I18n
  "crm.security.groups":"\u0917\u094D\u0930\u0941\u092A\u094D\u0938", //NO I18n
	"crm.label.available" : "\u0909\u092A\u0932\u092C\u094D\u0927", //NO I18n
	"crm.label.assign.manually" : "\u0906\u0935\u0902\u091F\u093F\u0924", //NO I18n
	"crm.globalsearch.option.all": "\u0938\u092D\u0940", //NO I18n
	"webform.status.Active":"\u0938\u0915\u094D\u0930\u093F\u092F", //NO I18n
	"Inactive":"\u0928\u093F\u0937\u094D\u0915\u094D\u0930\u093F\u092F", //NO I18n
  "Confirmed":"\u05de\u05d0\u05d5\u05e9\u05e8", //NO I18n
  "crm.user.component.unconfirmed":"\u05dc\u05d0 \u05d0\u05d5\u05e9\u05e8",//no i18n
  "DeletedUser":"\u0939\u091F\u093E\u090F \u0917\u090F", //NO I18n
  "crm.feed.group.admin":"\u0935\u094D\u092F\u0935\u0938\u094D\u0925\u093E\u092A\u0915", //NO I18n
  "crm.ln.lable.current":"\u0935\u0930\u094D\u0924\u092E\u093E\u0928", //NO I18n
	"crm.label.selected": "\u091A\u092F\u0928\u093F\u0924",//NO I18n
	"crm.auditlog.user": "\u0909\u092A\u092F\u094B\u0917\u0915\u0930\u094D\u0924\u093E", //NO I18n
	"cob.role": "Role", //NO I18n
	"zoho.email": "Email", //NO I18n
	"Profile": "Profile", //NO I18n
	"crm.security.group.users.empty": "\u0915\u094B\u0908 \u092F\u0942\u091C\u0930 \u0928\u0939\u0940\u0902 \u092E\u093F\u0932\u093E .", //NO I18n
	"crm.label.picklist.none": "--\u0915\u094B\u0908 \u0928\u0939\u0940\u0902--", //NO I18n
	"crm.zti.label.user": "\u092A\u094D\u0930\u092F\u094B\u0915\u094D\u0924\u093E \u0928\u093E\u092E", //NO I18n
	"crm.label.notSelected" : "\u091A\u092F\u0928\u093F\u0924 \u0928\u0939\u0940\u0902 \u0915\u093F\u092F\u093E \u0917\u092F\u093E",//NO I18n
	"AM" : "\u090F\u090F\u092E",//NO I18n
	"Call" : "\u0915\u0949\u0932",//NO I18n
	"crm.phoneNo.Link.Title" : "u0938\u094D\u0915\u093E\u0907\u092A \u0915\u093E \u092F\u0942\u095B \u0921\u093E\u092F\u0932 \u0915\u0930\u0947\u0902",//NO I18n
	"crm.no.data.found" : "\u0915\u094B\u0908 \u0921\u0947\u091F\u093E \u0928\u0939\u0940\u0902 \u092E\u093F\u0932\u093E.",//NO I18n
	"crm.usrpop.non.selected" : "Selected Users",//No I18n
	"crm.globalsearch.search.title" : "\u0916\u094B\u091C\u0947\u0902 ",//No I18n
	"None" : "\u0915\u094B\u0908 \u0928\u0939\u0940\u0902",//No I18n
	"crm.label.criteria.pattern" : "\u092E\u093E\u0928\u0926\u0902\u0921 \u092A\u0948\u091F\u0930\u094D\u0928 ",//No I18n
	"crm.label.edit.criteria.pattern" : "\u0906\u0915\u0943\u0924\u093F \u0915\u094B \u0938\u0902\u092A\u093E\u0926\u093F\u0924 \u0915\u0930\u0947\u0902",//No I18n
	"criteria.error.alert.brackets.count.mismatch" : "\u0906\u0915\u0943\u0924\u093F \u0915\u094B\u0937\u094D\u0920\u0915 \u092E\u0947\u0932 \u0928\u0939\u0940\u0902 \u0916\u093E\u0924\u0947.",//No I18n
	"criteria.error.alert.brackets.invalid" : "\u0938\u094D\u0925\u093F\u0924\u093F \u0911\u092A\u0930\u0947\u091F\u0930(\u0913\u0902) \u0915\u0947 \u0906\u0938-\u092A\u093E\u0938 \u0905\u092E\u093E\u0928\u094D\u092F \u0915\u094B\u0937\u094D\u0920\u0915",//No I18n
	"crm.criteria.number.notmatch.check" : "\u092A\u0930 {0} \u0906\u0915\u0943\u0924\u093F \u0915\u0940 \u091C\u093E\u0902\u091A \u0915\u0930\u0947\u0902.",//No I18n
	"criteria.error.alert.other.params" : "\u0907\u0938 \u0906\u0915\u0943\u0924\u093F \u092E\u0947\u0902 \u0905\u092E\u093E\u0928\u094D\u092F \u0924\u0924\u094D\u0935.", //No I18n
	"crm.label.search.for.users": "\u0909\u092A\u092F\u094B\u0917\u0915\u0930\u094D\u0924\u093E\u0913\u0902 \u0915\u094B \u0916\u094B\u091C\u0947\u0902", //NO I18n
	"criteria.error.alert.andor.rowcount.mismatch" : "\u0907\u0938 \u092E\u093E\u0928\u0926\u0902\u0921 \u092A\u0948\u091F\u0930\u094D\u0928 \u0906\u092A\u0915\u0947 \u0926\u094D\u0935\u093E\u0930\u093E \u091A\u0941\u0928\u0940 \u0917\u0908 \u0936\u0930\u094D\u0924\u094B\u0902 \u0938\u0947 \u092E\u0947\u0932 \u0928\u0939\u0940\u0902 \u0916\u093E\u0924\u0940.", //No I18n
	"criteria.error.alert.critnum.rowcount.mismatch" : "\u0907\u0938 \u092E\u093E\u0928\u0926\u0902\u0921 \u092A\u0948\u091F\u0930\u094D\u0928 \u0906\u092A\u0915\u0947 \u0926\u094D\u0935\u093E\u0930\u093E \u091A\u0941\u0928\u0940 \u0917\u0908 \u0936\u0930\u094D\u0924\u094B\u0902 \u0938\u0947 \u092E\u0947\u0932 \u0928\u0939\u0940\u0902 \u0916\u093E\u0924\u0940.", //No I18n
	"and" : "\u0914\u0930", //No I18n
	"or" : "\u092F\u093E", //No I18n
	"crm.label.or" : "\u092F\u093E ", //No I18n
	"crm.label.and" : "\u0914\u0930 ", //No I18n
	"crm.criteria.fieldlabel.valid.check" : "{0} \u092A\u0902\u0915\u094D\u0924\u093F \u092E\u0947\u0902 \u090F\u0915 \u092E\u093E\u0928\u094D\u092F \u0938\u094D\u0925\u093E\u0928 \u0932\u0947\u092C\u0932 \u0926\u0930\u094D\u091C \u0915\u0930\u0947\u0902\u0964", //No I18n
	"crm.criteria.condition.valid.check" : "{0} \u0915\u0947 \u0932\u093F\u090F \u090F\u0915 \u092E\u093E\u0928\u094D\u092F \u0938\u094D\u0925\u093F\u0924\u093F \u0928\u093F\u0930\u094D\u0926\u093F\u0937\u094D\u091F \u0915\u0930\u0947\u0902.", //No I18n
	"crm.field.valid.check" : "\u0915\u0943\u092A\u092F\u093E \u0935\u0948\u0927 {0} \u092A\u094D\u0930\u0935\u093F\u0937\u094D\u091F\u0928 \u0915\u0930\u0947\u0902.", //No I18n
	"crm.alert.label.savepattern" : "\u092E\u093E\u0928\u0915 \u092C\u0926\u0932\u0928\u0947 \u0938\u0947 \u092A\u0939\u0932\u0947 \u0906\u0915\u0943\u0924\u093F \u0915\u094B \u0938\u0939\u0947\u091C\u0947\u0902.",//No I18n
	"crm.criteria.max.rowcnt.exceeds" : "\u0906\u092A \u0905\u0924\u093F\u0930\u093F\u0915\u094D\u0924 \u092E\u093E\u0928\u0915 \u0928\u0939\u0940\u0902 \u091C\u094B\u0921\u093C \u0938\u0915\u0924\u0947",//No I18n
	"is" : "\u0939\u0948",//No I18n
	"isn\'t" : "\u0928\u0939\u0940\u0902 \u0939\u0948",//No I18n
	"contains" : "\u092E\u094C\u091C\u0942\u0926 \u0939\u0948",//No I18n
	"doesn\'t contain" : "\u0928\u0939\u0940\u0902 \u092E\u094C\u091C\u0942\u0926 \u0939\u0948",//No I18n
	"starts with" : "\u0938\u0947 \u0936\u0941\u0930\u0942 \u0939\u094B\u0924\u093E \u0939\u0948",//No I18n
	"ends with" : "\u0938\u0947 \u0938\u092E\u093E\u092A\u094D\u0924 \u0939\u094B\u0924\u093E \u0939\u0948",//No I18n
	"is empty" : "\u0916\u093E\u0932\u0940 \u0939\u0948",//No I18n
	"is not empty" : "\u0916\u093E\u0932\u0940 \u0928\u0939\u0940\u0902 \u0939\u0948",//No I18n
	"is before" : "\u0915\u0947 \u092A\u0939\u0932\u0947 \u0939\u0948",//No I18n
	"is after" : "\u0915\u0947 \u092C\u093E\u0926 \u0939\u0948",//No I18n
	"between" : "\u0915\u0947 \u092C\u0940\u091A",//No I18n
	"not between" : "\u092C\u0940\u091A \u092E\u0947\u0902 \u0928\u0939\u0940\u0902",//No I18n
	"Today" : "\u0906\u091C",//No I18n
	"Tommorow" : "\u0915\u0932",//No I18n
	"Tommorow Onwards" : "\u0915\u0932 \u0936\u0941\u0930\u0942 \u0939\u094B \u0930\u0939\u093E \u0939\u0948\u0902",//No I18n
	"Yesterday" : "\u0915\u0932",//No I18n
	"Till Yesterday" : "\u0915\u0932 \u0924\u0915",//No I18n
	"Last Month" : "\u092A\u093F\u091B\u0932\u093E \u092E\u0939\u0940\u0928\u093E",//No I18n
	"Current Month" : "\u0935\u0930\u094D\u0924\u092E\u093E\u0928 \u092E\u0939\u0940\u0928\u093E ", //No I18n
	"Next Month" : "\u0905\u0917\u0932\u093E \u092E\u0939\u0940\u0928\u093E ", //No I18n
	"Last Week" : "\u092A\u093F\u091B\u0932\u093E \u0938\u092A\u094D\u0924\u093E\u0939 ", //No I18n
	"Current Week" : "\u0935\u0930\u094D\u0924\u092E\u093E\u0928 \u0938\u092A\u094D\u0924\u093E\u0939 ", //No I18n
	"Next Week" : "\u0905\u0917\u0932\u093E \u0938\u092A\u094D\u0924\u093E\u0939 ", //No I18n
	"Age in Days" : "\u0926\u093F\u0928\u094B\u0902 \u092E\u0947\u0902 \u0909\u092E\u094D\u0930 ", //No I18n
	"Due in Days" : "\u0915\u093F\u0924\u0928\u0947 \u0926\u093F\u0928 \u092E\u0947\u0902 \u0926\u0947\u092F ", //No I18n
	"Scheduled" : "\u0936\u0947\u0921\u094D\u092F\u0942\u0932\u094D\u0921", //No I18n
	"Attended Dialled" : "\u0921\u093E\u092F\u0932 \u0915\u093F\u092F\u093E \u0914\u0930 \u092D\u093E\u0917 \u0932\u093F\u092F\u093E ", //No I18n
	"Unattended Dialled" : "\u0928\u093E\u092F\u093E\u092C \u0921\u093E\u092F\u0932 \u0915\u093F\u092F\u093E \u0917\u092F\u093E", //No I18n
	"Overdue" : "\u0905\u0924\u093F\u0926\u0947\u092F", //No I18n
	"Cancelled" : "\u0930\u0926\u094D\u0926 \u0915\u093F\u092F\u093E ", //No I18n
	"Received" : "\u092A\u094D\u0930\u093E\u092A\u094D\u0924 \u0939\u0941\u0906", //No I18n
	"Missed" : "\u092E\u093F\u0938\u094D\u0921 ", //No I18n
	"crm.alert.character.not.allowed" : "{0} \u0915\u0940 \u0905\u0928\u0941\u092E\u0924\u093F \u0928\u0939\u0940\u0902 \u0939\u0948", //No I18n
	"crm.condition.in.last" : "\u0906\u0916\u093F\u0930\u0940 \u092E\u0947\u0902", //No I18n
	"crm.zinvoice.dueIn" : "\u0924\u0915 \u0926\u0947\u092F", //No I18n
	"on" : "\u0915\u094B",//No I18n
	"before" : "\u0938\u0947 \u092A\u0939\u0932\u0947",//No I18n
	"crm.label.general.small.after" : "\u0915\u0947 \u092C\u093E\u0926 ",//No I18n
	"crm.thisweek" : "\u0907\u0938 \u0938\u092A\u094D\u0924\u093E\u0939",//No I18n
	"crm.label.this.month" : "\u0907\u0938 \u092E\u0939\u0940\u0928\u0947",//No I18n
	"crm.thisyear" : "\u0907\u0938 \u0938\u093E\u0932",//No I18n
	"crm.source.user.and.system" : "\u0909\u092a\u092f\u094b\u0917\u0915\u0930\u094d\u0924\u093e \u090f\u0935\u0902 \u0938\u093f\u0938\u094d\u091f\u092e",//No I18n
	"crm.source.user.or.system" : "\u0909\u092a\u092f\u094b\u0917\u0915\u0930\u094d\u0924\u093e \u092f\u093e \u0938\u093f\u0938\u094d\u091f\u092e",//No I18n
	"crm.label.system2" : "\u0938\u093F\u0938\u094D\u091F\u092E",//No I18n
	"crm.source.user.only" : "\u0915\u0947\u0935\u0932 \u0909\u092a\u092f\u094b\u0917\u0915\u0930\u094d\u0924\u093e \u0926\u094d\u0935\u093e\u0930\u093e",//No I18n
	"crm.source.system.only" : "\u0915\u0947\u0935\u0932 \u0938\u093f\u0938\u094d\u091f\u092e \u0926\u094d\u0935\u093e\u0930\u093e",//No I18n
	"crm.condition.till.today" : "\u0906\u091C \u0924\u0915",//No I18n
	"game.month.left" : "1 \u092E\u0939\u0940\u0928\u093E",//No I18n
	"game.months.left" : "{0} \u092E\u0939\u0940\u0928\u0947",//No I18n
	"crm.intelligence.prediction.trendup" :"\u092c\u095d\u0924\u093e \u0930\u0942\u091d\u093e\u0928" ,//no i18n
	"crm.intelligence.prediction.trenddown" :"\u0918\u091f\u0924\u093e \u0930\u0942\u091d\u093e\u0928" ,//no i18n
	"crm.zia.prediction.notrend":"No trend",//no i18n
	"crm.zia.prediction.likelihood":"Likelihood Score",//no i18n
	"Completed":"\u092a\u0942\u0930\u094d\u0923 \u0939\u094b \u0917\u092f\u093e" ,//no i18n
	"crm.label.success":"\u0938\u092b\u0932" ,//no i18n
	"crm.label.Failure":"\u0905\u0938\u092b\u0932" ,//no i18n
	"Both":"\u0926\u094b\u0928\u094b\u0902" ,//no i18n
	"crm.condition.cannot.empty":"\u0936\u0930\u094d\u0924 \u0930\u093f\u0915\u094d\u0924 \u0928\u0939\u0940\u0902 \u0930\u0939 \u0938\u0915\u0924\u0940\u0964",//no i18n
	"crm.predictions.feature.label":"\u092a\u0942\u0930\u094d\u0935\u093e\u0928\u0941\u092e\u093e\u0928",//no I18n
	"crm.custom.field.less.than.to1":"\u201c\u0938\u0947\u201d \u0936\u094d\u0930\u0947\u0923\u0940 \u201c\u0924\u0915\u201d \u0936\u094d\u0930\u0947\u0923\u0940 \u0938\u0947 \u092c\u0921\u093c\u0940 \u0928\u0939\u0940\u0902 \u0939\u094b \u0938\u0915\u0924\u0940\u0964",//no i18n
	"crm.condition.last.30.days" : "\u092a\u093f\u091b\u0932\u0947 30 \u0926\u093f\u0928\u094b\u0902 \u092e\u0947\u0902",//No I18n
	"crm.condition.last.60.days" : "\u092a\u093f\u091b\u0932\u0947 60 \u0926\u093f\u0928\u094b\u0902 \u092e\u0947\u0902",//No I18n
	"crm.condition.last.90.days" : "\u092a\u093f\u091b\u0932\u0947 90 \u0926\u093f\u0928\u094b\u0902 \u092e\u0947\u0902",//No I18n
	"crm.label.filter.typehere" : "\u092F\u0939\u093E\u0901 \u091F\u093E\u0907\u092A \u0915\u0930\u0947\u0902", //No I18N
	"crm.filter.is.not" : "\u0928\u0939\u0940\u0902 \u0939\u0948", //No I18n
	"crm.condition.until.now" : "\u0905\u092c \u0924\u0915",//No I18n
	"crm.filter.email.isblocked" : "\u0905\u0935\u0930\u0941\u0926\u094d\u0927 \u0939\u0948",//No I18n
	"crm.filter.email.isnotblocked" : "\u0905\u0935\u0930\u0941\u0926\u094d\u0927 \u0928\u0939\u0940\u0902 \u0939\u0948",//No I18n
	"crm.label.no.results.match" : "\u0915\u093F\u0938\u0940 \u092A\u0930\u093F\u0923\u093E\u092E \u0915\u093E \u092E\u093F\u0932\u093E\u0928 \u0928\u0939\u0940\u0902 \u0939\u0941\u0906",//No I18n
	"crm.label.select.user" : "\u0909\u092A\u092F\u094B\u0917\u0915\u0930\u094D\u0924\u093E\u0913\u0902 \u0915\u094B \u091A\u0941\u0928\u0928\u0947 \u0915\u0947 \u0932\u093F\u090F \u0915\u094D\u0932\u093F\u0915 \u0915\u0930\u0947\u0902.", //No I18n
	"current.logged.in.user": "\u0932\u0949\u0917 \u0907\u0928 \u0915\u093f\u090f \u0939\u0941\u090f \u0909\u092a\u092f\u094b\u0917\u0915\u0930\u094d\u0924\u093e", //NO I18n
	"current.logged.in.user.definition": "\u0935\u0939 \u0909\u092a\u092f\u094b\u0917\u0915\u0930\u094d\u0924\u093e \u091c\u094b \u201c\u0930\u093f\u0915\u0949\u0930\u094d\u0921 \u0915\u093e\u0930\u094d\u0930\u0935\u093e\u0908\u201d \u0906\u0930\u0902\u092d \u0915\u0930\u0924\u093e \u0939\u0948\u0964", //NO i18n
	"crm.security.group": "\u0917\u094D\u0930\u0941\u092A", //NO I18n
	"crm.security.role": "\u0930\u094B\u0932", //NO I18n
	"Date" : "\u0924\u093F\u0925\u093F",//No I18n
	"crm.field.valid.decimal.check2" : "<i>{0}</i> \u0915\u0947 \u0932\u093F\u090F \u0926\u0936\u092E\u0932\u0935 \u0915\u0947 \u0938\u094D\u0925\u093E\u0928\u094B\u0902 \u0915\u094B {1} \u0938\u0947 \u0915\u092E \u092F\u093E \u0909\u0938\u0915\u0947 \u092C\u0930\u093E\u092C\u0930 \u0939\u094B\u0928\u093E \u091A\u093E\u0939\u093F\u090F\u0964",//No I18n
	"crm.field.empty.check" : "{0} \u0916\u093E\u0932\u0940 \u0928\u0939\u0940\u0902 \u0930\u0939 \u0938\u0915\u0924\u093E \u0939\u0948 .",//No I18n
	"crm.label.add.note": "\u0928\u094B\u091F \u091C\u094B\u0921\u093C\u0947", //NO I18n
	"crm.label.simply.by": "\u0926\u094D\u0935\u093E\u0930\u093E", //NO I18n
	"crm.general.addnote": "\u091F\u093F\u092A\u094D\u092A\u0915\u0923\u0940 \u091C\u094B\u0921\u093C\u0947\u0902", //NO I18n
	"crm.general.addtitle": "\u0936\u0940\u0930\u094D\u0937\u0915 \u091C\u094B\u0921\u093C\u0947\u0902", //NO I18n
	"crm.label.attach.file": "\u092B\u093E\u0907\u0932 \u091C\u094B\u0921\u093C\u0947\u0902", //NO I18N
	"crm.button.cancel": "\u0930\u0926\u094D\u0926 \u0915\u0930\u0947\u0902", //NO I18N
	"crm.button.save": "\u0938\u0939\u0947\u091C\u0947\u0902", //NO I18N
	"crm.button.mass.delete": "\u092E\u093F\u091F\u093E\u090F\u0902", //NO I18N
	"crm.warning.delete.record": '\u0915\u094D\u092F\u093E \u0906\u092A \u0938\u091A\u092E\u0941\u091A \u201C{0}" \u0915\u094B \u0915\u091A\u0930\u093E \u092A\u0947\u091F\u0940 \u092E\u0947\u0902 \u0932\u0947 \u091C\u093E\u0928\u093E \u091A\u093E\u0939\u0924\u0947 \u0939\u0948\u0902?', //NO I18N
	"crm.label.yes": "\u0939\u093E\u0902", //NO I18N
	"crm.note.view.previous": "\u092A\u0939\u0932\u0947 \u0915\u0940 \u091F\u093F\u092A\u094D\u092A\u0928\u0923\u093F\u092F\u093E\u0902 \u0926\u0947\u0916\u0947\u0902", //NO I18N
  "of": "\u0915\u093E", //NO I18N
	"crm.label.notes": "\u091F\u093F\u092A\u094D\u092Ae\u0923\u093F\u092F\u093E\u0902", //NO I18N
	"crm.note.recent.first": "\u0939\u093E\u0932 \u0939\u0940 \u0915\u0940 \u092A\u0939\u0932\u0940", //NO I18N
	"crm.note.recent.last": "\u0939\u093E\u0932 \u0939\u0940 \u0915\u0940 \u0905\u0902\u0924\u093F\u092E", //NO I18N
	"crm.territory.label.only": "\u0915\u0947\u0935\u0932 {0}", //no i18n
	"crm.select" : "\u091A\u0941\u0928\u0947\u0902",//No I18n
	"crm.button.apply.filter" : "\u092B\u093F\u0932\u094D\u091F\u0930 \u0932\u093E\u0917\u0942 \u0915\u0930\u0947\u0902 ",//No I18n
	"crm.alert.maximum.text.values.contains" : "\u0906\u092A \u0907\u0938 \u092B\u0940\u0932\u094D\u0921 \u0915\u0947 \u0932\u093F\u090F {0} \u0938\u0947 \u0905\u0927\u093F\u0915 \u092E\u0942\u0932\u094D\u092F\u094B\u0902 \u0915\u094B \u0928\u0939\u0940\u0902 \u092A\u094D\u0930\u0935\u093F\u0937\u094D\u091F \u0915\u0930 \u0938\u0915\u0924\u0947\u0964",//No I18n
	"PM" : "\u092A\u0940\u090F\u092E",//No I18n
	"crm.mb.newversion.msg4" : "\u0920\u0940\u0915 \u0939\u0948\u0902\u0964 \u092E\u0948 \u0938\u092E\u091D \u0917\u092F\u093E\u0964", //no i18n
	"Jan" : "\u091C\u0928\u0935\u0930\u0940", //no i18n
	"Feb" : "\u092B\u093C\u0930\u0935\u0930\u0940", //no i18n
	"Mar" : "\u092E\u093E\u0930\u094D\u091A", //no i18n
	"Apr" : "\u0905\u092A\u094D\u0930\u0948\u0932", //no i18n
	"Jun" : "\u091C\u0942\u0928", //no i18n
	"Jul" : "\u091C\u0941\u0932\u093E\u0908", //no i18n
	"Aug" : "\u0905\u0917\u0938\u094D\u0924", //no i18n
	"Sep" : "\u0938\u093F\u0924\u092E\u094D\u092C\u0930", //no i18n
	"Oct" : "\u0905\u0915\u094D\u091F\u0942\u092C\u0930", //no i18n
	"Nov" : "\u0928\u0935\u092E\u094D\u092C\u0930", //no i18n
	"Dec" : "\u0926\u093F\u0938\u092E\u094D\u092C\u0930", //no i18n
	"crm.label.More" :"\u0905\u0927\u093F\u0915", //no i18n

  	"crm.label.unmapped.stages":"\u092C\u093F\u0928\u093E \u0917\u093F\u0928\u0924\u0940 \u0915\u093E", //no i18n
  	"crm.wf.summary.label.ShowInstantActions" : "\u0914\u0930 \u0926\u093F\u0916\u093E\u090F\u0902",//No I18n
	 "crm.wf.summary.label.HideInstantActions" : "\u0915\u092E \u0926\u093F\u0916\u093E\u090F\u0902",//No I18n

	 	 //filter related keys-start
	 	 "crm.filters.select.campaign.status" :"{0} \u0938\u094d\u0925\u093f\u0924\u093f \u0915\u093e \u091a\u092f\u0928 \u0915\u0930\u0947\u0902" ,//no i18n
	 	 "campaign.Member" : "\u0938\u0926\u0938\u094d\u092f",//no i18n
	"Service":"\u0938\u0947\u0935\u093e",//no i18n
	 "crm.website.activity" : "\u0935\u0947\u092c\u0938\u093e\u0907\u091f \u0915\u0940 \u0917\u0924\u093f\u0935\u093f\u0927\u093f",//No I18n
	 "crm.label.touched.records" : "\u0938\u094d\u092a\u0930\u094d\u0936 \u0915\u093f\u090f \u0917\u090f \u0930\u093f\u0915\u0949\u0930\u094d\u0921",//No I18n
	"crm.label.untouched.records":"\u0905\u091b\u0942\u0924\u0947 \u0930\u093f\u0915\u0949\u0930\u094d\u0921",//No I18n
	"crm.label.record.action"	: "\u0915\u093e\u0930\u094d\u0930\u0935\u093e\u0908 \u0930\u093f\u0915\u0949\u0930\u094d\u0921 \u0915\u0930\u0947\u0902",//No I18n
	"crm.label.related.records.action":"\u0938\u0902\u092c\u0902\u0927\u093f\u0924 \u0930\u093f\u0915\u0949\u0930\u094d\u0921\u094d\u0938 \u0915\u093e\u0930\u094d\u0930\u0935\u093e\u0908",//No I18n
	"crm.inv.mail.time.two.days.subject":"{0} - {1}",//no i18n
	"Planned":"\u0915\u0940 \u092F\u094B\u091C\u0928\u093E \u092C\u0928\u093E\u0908",//no i18n
	"Invited":"\u0906\u092E\u0902\u0924\u094D\u0930\u093F\u0924 \u0915\u093F\u092F\u093E",//no i18n
	"Sent":"\u092D\u0947\u091C\u093E \u0917\u092F\u093E",//no i18n
	"Received":"\u092A\u094D\u0930\u093E\u092A\u094D\u0924 \u0939\u0941\u0906",//no i18n
	"Opened":"\u0916\u094B\u0932\u093E",//no i18n
	"Responded":"\u092A\u094D\u0930\u0924\u093F\u0915\u094D\u0930\u093F\u092F\u093E \u095B\u093E\u0939\u093F\u0930 \u0915\u0940",//no i18n
	"Bounced":"\u0935\u093E\u092A\u093F\u0938 \u0939\u094B \u0917\u092F\u093E",//no i18n
	"Opted\ Out":"\u092C\u093E\u0939\u0930 \u0928\u093F\u0915\u0932\u0928\u0947 \u0915\u093E \u0935\u093F\u0915\u0932\u094D\u092A \u091A\u0941\u0928\u093E",//no i18n
	"crm.filter.label.activity.due":"{0} \u0936\u0947\u0937",//no i18n
	"crm.filter.label.activity.done":"{0} \u0939\u094B \u0917\u092F\u093E",//no i18n
	"Notes":"\u0928\u094B\u091F\u094D\u0938",//no i18n
	"crm.filter.label.notes.added":"\u0928\u094B\u091F\u094B\u0902 \u091C\u094B\u0921\u093C\u0947 \u0917\u090F",//no i18n
	"crm.filter.label.sent":"\u092D\u0947\u091C \u0926\u093F\u092F\u093E",//no i18n
	"crm.filter.label.not.sent":"\u0928\u0939\u0940\u0902 \u092D\u0947\u091C\u093E \u0917\u092F\u093E",//no i18n
	"crm.filter.label.opened":"\u0916\u094B\u0932\u093E",//no i18n
	"crm.filter.label.not.opened":"\u0928\u0939\u0940\u0902 \u0916\u094B\u0932\u093E",//no i18n
	"crm.filter.label.received":"\u092A\u094D\u0930\u093E\u092A\u094D\u0924 \u0915\u093F\u092F\u093E",//no i18n
	"crm.filter.label.not.received":"\u0928\u0939\u0940 \u092E\u093F\u0932\u093E",//no i18n
	"crm.filter.label.bounced":"\u092C\u093E\u0909\u0902\u0938\u094D\u0921",//no i18n
	"crm.filter.label.opened.not.replied":"\u0916\u094B\u0932\u093E \u0914\u0930 \u091C\u0935\u093E\u092C \u0928\u0939\u0940\u0902 \u0926\u093F\u092F\u093E",//no i18n
	"Quote\ Stage":"{0} \u091A\u0930\u0923",//no i18n
	"crm.module.owner":"{0} \u092E\u093E\u0932\u093F\u0915",//no i18n
	"Potential\ Closing\ Date":"{0} \u0938\u092E\u093E\u092A\u094D\u0924\u093F \u0924\u093F\u0925\u093F ",//no i18n
	"workflow.rule.view.label.Modified":"\u092A\u0930\u093F\u0935\u0930\u094D\u0924\u093F\u0924",//no i18n
	"Done":"\u0939\u094B \u0917\u092F\u093E",//no i18n
	"Last\ Activity\ Date":"\u092A\u093F\u091B\u0932\u0947 \u0917\u0924\u093F\u0935\u093F\u0927\u093F \u0915\u0940 \u0924\u093F\u0925\u093F",//no i18n
	"crm.label.vendor.name":"{0} \u0928\u093E\u092E",//no i18n
	"hours":"\u0918\u0902\u091f\u0947",//no i18n
	"days":"\u0926\u093F\u0928",//no i18n
	"weeks":"\u0939\u092B\u094D\u0924\u0947",//no i18n
	"months":"\u092E\u0939\u0940\u0928\u0947",//no i18n
	"years":"\u0935\u0930\u094d\u0937",//no i18n
	"crm.label.general.small.after":"\u0915\u0947 \u092C\u093E\u0926",//no i18n
	"Last\ Week":"\u092A\u093F\u091B\u0932\u093E \u0938\u092A\u094D\u0924\u093E\u0939 ",//no i18n
	"Last\ Month":"\u092A\u093F\u091B\u0932\u093E \u092E\u0939\u0940\u0928\u093E",//no i18n
	"crm.module.name":"{0} \u0928\u093E\u092E",//no i18n
	"Campaign":"\u0905\u092D\u093F\u092F\u093E\u0928 ",//no i18n
	"Tasks":"\u0915\u093E\u0930\u094D\u092F",//no i18n
	"Calls":"\u0915\u0949\u0932\u094D\u0938",//no i18n
	"Events":"\u0915\u093E\u0930\u094D\u092F\u0915\u094D\u0930\u092E",//no i18n
	"Planned":"\u0915\u0940 \u092F\u094B\u091C\u0928\u093E \u092C\u0928\u093E\u0908",//no i18n
	"Invited":"\u0906\u092E\u0902\u0924\u094D\u0930\u093F\u0924 \u0915\u093F\u092F\u093E",//no i18n
	"Sent":"\u092D\u0947\u091C\u093E \u0917\u092F\u093E",//no i18n
	"Received":"\u092A\u094D\u0930\u093E\u092A\u094D\u0924 \u0939\u0941\u0906",//no i18n
	"Opened":"\u0916\u094B\u0932\u093E",//no i18n
	"Responded":"\u092A\u094D\u0930\u0924\u093F\u0915\u094D\u0930\u093F\u092F\u093E \u095B\u093E\u0939\u093F\u0930 \u0915\u0940",//no i18n
	"Bounced":"\u0935\u093E\u092A\u093F\u0938 \u0939\u094B \u0917\u092F\u093E",//no i18n
	"Opted\ Out":"\u092C\u093E\u0939\u0930 \u0928\u093F\u0915\u0932\u0928\u0947 \u0915\u093E \u0935\u093F\u0915\u0932\u094D\u092A \u091A\u0941\u0928\u093E",//no i18n
	"crm.filter.label.without.open":"\u0913\u092A\u0928 \u092C\u093F\u0928\u093E {0}",//no i18n
	"crm.filter.label.without.any":"\u092C\u093F\u0928\u093E \u0915\u093F\u0938\u0940 \u092D\u0940 {0}",//no i18n
	"crm.filter.label.activity.due":"{0} \u0936\u0947\u0937",//no i18n
	"crm.filter.label.activity.done":"{0} \u0939\u094B \u0917\u092F\u093E",//no i18n
	"Notes":"\u0928\u094B\u091F\u094D\u0938",//no i18n
	"crm.filter.label.notes.added":"\u0928\u094B\u091F\u094B\u0902 \u091C\u094B\u0921\u093C\u0947 \u0917\u090F",//no i18n
	"crm.label.filter.email.status":"\u0908\u092E\u0947\u0932 \u0915\u0940 \u0938\u094D\u0925\u093F\u0924\u093F",//no i18n
	"crm.label.filter.email.responded":"\u092a\u094d\u0930\u0924\u094d\u092f\u0941\u0924\u094d\u0924\u0930 \u0926\u093f\u092f\u093e", //no i18n
	"crm.filter.label.sent":"\u092D\u0947\u091C \u0926\u093F\u092F\u093E",//no i18n
	"crm.filter.label.not.sent":"\u0928\u0939\u0940\u0902 \u092D\u0947\u091C\u093E \u0917\u092F\u093E",//no i18n
	"crm.filter.label.opened":"\u0916\u094B\u0932\u093E",//no i18n
	"crm.filter.label.not.opened":"\u0928\u0939\u0940\u0902 \u0916\u094B\u0932\u093E",//no i18n
	"crm.filter.label.received":"\u092A\u094D\u0930\u093E\u092A\u094D\u0924 \u0915\u093F\u092F\u093E",//no i18n
	"crm.filter.label.not.received":"\u0928\u0939\u0940 \u092E\u093F\u0932\u093E",//no i18n
	"crm.filter.label.bounced":"\u092C\u093E\u0909\u0902\u0938\u094D\u0921",//no i18n
	"crm.filter.label.opened.not.replied":"\u0916\u094b\u0932\u093e \u0914\u0930 \u092a\u094d\u0930\u0924\u094d\u092f\u0941\u0924\u094d\u0924\u0930 \u0928\u0939\u0940\u0902 \u0926\u093f\u092f\u093e", //no i18n
	"Quote\ Stage":"{0} \u091A\u0930\u0923",//no i18n
	"crm.module.owner":"{0} \u092E\u093E\u0932\u093F\u0915",//no i18n
	"Potential\ Closing\ Date":"{0} \u0938\u092E\u093E\u092A\u094D\u0924\u093F \u0924\u093F\u0925\u093F ",//no i18n
	"workflow.rule.view.label.Modified":"\u092A\u0930\u093F\u0935\u0930\u094D\u0924\u093F\u0924",//no i18n
	"Done":"\u0939\u094B \u0917\u092F\u093E",//no i18n
	"Last\ Activity\ Date":"\u092A\u093F\u091B\u0932\u0947 \u0917\u0924\u093F\u0935\u093F\u0927\u093F \u0915\u0940 \u0924\u093F\u0925\u093F",//no i18n
	"crm.label.vendor.name":"{0} \u0928\u093E\u092E",//no i18n
	"days":"\u0926\u093F\u0928",//no i18n
	"weeks":"\u0939\u092B\u094D\u0924\u0947",//no i18n
	"months":"\u092E\u0939\u0940\u0928\u0947",//no i18n
	"crm.label.general.small.after":"\u0915\u0947 \u092C\u093E\u0926",//no i18n
	"Last\ Week":"\u092A\u093F\u091B\u0932\u093E \u0938\u092A\u094D\u0924\u093E\u0939 ",//no i18n
	"Last\ Month":"\u092A\u093F\u091B\u0932\u093E \u092E\u0939\u0940\u0928\u093E",//no i18n
	"crm.module.name":"{0} \u0928\u093E\u092E",//no i18n
	"Campaign":"\u0905\u092D\u093F\u092F\u093E\u0928 ",//no i18n
	"Tasks":"\u0915\u093e\u0930\u094d\u092f",//no i18n
	"Calls":"\u0915\u0949\u0932\u094D\u0938",//no i18n
	"Events":"\u0915\u093E\u0930\u094D\u092F\u0915\u094D\u0930\u092E",//no i18n
	"Activities":"\u0917\u0924\u093F\u0935\u093F\u0927\u093F\u092F\u093E\u0902",//no i18n
	"crm.livedesk.pot.nextdays":"\u0905\u0917\u0932\u093E {0} \u0926\u093F\u0928",//no i18n
	"Today\ +\ Overdue":"\u0906\u091C + \u0913\u0935\u0930\u0921\u094D\u092F\u0942",//no i18n
	"User":"\u092F\u0942\u091C\u0930",//no i18n
	"Scheduled":"\u0936\u0947\u0921\u094D\u092F\u0942\u0932\u094D\u0921",//no i18n
	"Attended\ Dialled":"\u0921\u093E\u092F\u0932 \u0915\u093F\u092F\u093E \u0914\u0930 \u092D\u093E\u0917 \u0932\u093F\u092F\u093E",//no i18n
	"Unattended\ Dialled":"\u0928\u093E\u092F\u093E\u092C \u0921\u093E\u092F\u0932 \u0915\u093F\u092F\u093E \u0917\u092F\u093E",//no i18n
	"Cancelled":"\u0930\u0926\u094D\u0926 \u0915\u093F\u092F\u093E ",//no i18n
	"crm.recurring.no.months":"{0} \u092E\u0939\u0940\u0928\u0947",//no i18n
	"crm.events.duration":"\u0905\u0935\u0927\u093F",//no i18n
	"crm.title.clear.name":"\u0938\u094D\u092A\u0937\u094D\u091F",//no i18n
	"crm.label.status.is":"\u0914\u0930 \u0938\u094D\u0924\u0930 \u0939\u0948",//no i18n

	//filter related keys-End
	"crm.label.account.created" : "\u0928\u092F\u093E {0} \u092C\u0928\u093E \u0926\u093F\u092F\u093E \u091C\u093E\u090F\u0917\u093E ",//No I18n
	"crm.krp.no.records.found" : "{0} \u0928\u0939\u0940\u0902 \u092E\u093F\u0932\u0947",//No I18n
	"crm.module.new" : "{0} \u0928\u092F\u093E ",//No I18n
	"crm.label.view" : "\u0926\u0947\u0916\u0947\u0902",//No I18n
	"crm.nsocial.customers" : "\u0917\u094D\u0930\u093E\u0939\u0915\u094B\u0902",//No I18n
	"crm.nsocial.open.potential" : "\u0916\u0941\u0932\u0947\u0902 {0}",//No I18n
	"crm.nsocial.lead.contact" : "",//No i18n
	"Others" : "{0}/{1}",//No i18n
	"crm.field.length.check" : "{0} \u092E\u0942\u0932\u094D\u092F \u0905\u0927\u093F\u0915\u0924\u092E \u0938\u0940\u092E\u093E \u092A\u093E\u0930 \u0915\u0930 \u0930\u0939\u0940 \u0939\u0948.", //No I18n
	"crm.lower.now":"\u0905\u092C",//no i18n
	"crm.time.min.ago":"{0} \u092E\u093F\u0928\u091F \u092A\u0939\u0932\u0947",//no i18n
	"crm.time.mins.ago":"{0} \u092E\u093F\u0928\u091F. \u092A\u0939\u0932\u0947",//no i18n
	"crm.time.hr.ago":"{0} \u0918\u0902\u091F\u093E. \u092A\u0939\u0932\u0947",//no i18n
	"crm.time.hrs.ago":"{0} \u0918\u0902\u091F\u0947. \u092A\u0939\u0932\u0947", //no i18n
	"AllUsers": "\u0938\u092D\u0940 \u092F\u0942\u091C\u0930\u094D\u0938", //no i18n
	"crm.label.search":"\u0916\u094B\u091C\u0947\u0902",//no i18n
	"crm.api.filterby":"\u0926\u094D\u0935\u093E\u0930\u093E \u092B\u093F\u0932\u094D\u091F\u0930",//no i18n
	"crm.customview.nofields.found":"-\u092E\u0948\u091A \u0939\u094B\u0928\u0947\u0935\u093E\u0932\u093E \u0915\u094B\u0908 \u092B\u093C\u0940\u0932\u094D\u0921\u094D\u0938 \u0928\u0939\u0940\u0902 --",//no i18n
	"crm.mb.field.common.empt":"\u092E\u0942\u0932\u094D\u092F \u0916\u093E\u0932\u0940 \u0928\u0939\u0940\u0902 \u0939\u094B \u0938\u0915\u0924\u093E",//no i18n
	"crm.mxnlookup.select" : "Assign {0}",//No I18n
	"crm.lookup.chooserecord":"{0} \u091a\u0941\u0928\u0947\u0902",//no i18n
	"crm.record.selected":"\u091a\u092f\u0928\u093f\u0924 {0}",//no i18n
	"crm.module.empty.message" : "\u0915\u094B\u0908 {0} \u0928\u0939\u0940\u0902 \u092E\u093F\u0932\u093E",//No I18n
	"crm.mxnlookup.selected" : "Assigned {0}",//No I18n
	"crm.label.creator.noPermission" : "\u0905\u0928\u0941\u092E\u0924\u093F \u0928\u0939\u0940\u0902 \u092E\u093F\u0932\u0940", //no i18n
	"crm.security.error" : "\u0907\u0938 \u092A\u094D\u0930\u091A\u093E\u0932\u0928 \u0915\u094B \u0915\u0930\u0928\u0947 \u0915\u0947 \u0932\u093F\u090F \u0905\u092A\u0930\u094D\u092F\u093E\u092A\u094D\u0924 \u0905\u0927\u093F\u0915\u093E\u0930\u0964 \u0905\u092A\u0928\u0947 \u0935\u094D\u092F\u0925\u0935\u0938\u094D\u0925\u093E\u092A\u0915 \u0938\u0947 \u0938\u0902\u092A\u0930\u094D\u0915 \u0915\u0930\u0947\u0902", //no i18n
	"crm.segmentation.recency" : "\u0928\u0935\u0940\u0928\u0924\u093e", //No I18n
	"crm.segmentation.frequency" : "\u092b\u094d\u0930\u0940\u0915\u094d\u0935\u0947\u0902\u0938\u0940", //No I18n
	"crm.segmentation.monetary" : "\u092e\u094c\u0926\u094d\u0930\u093f\u0915", //No I18n
	"crm.segmentation.segment.score" : "\u0905\u0928\u0941\u092d\u093e\u0917 \u0938\u094d\u0915\u094b\u0930", //No I18n
	"crm.reviewprocess.smart.filter" : "\u092a\u094d\u0930\u0915\u094d\u0930\u093f\u092f\u093e \u0930\u093f\u0915\u0949\u0930\u094d\u0921 \u0915\u0940 \u0938\u092e\u0940\u0915\u094d\u0937\u093e \u0915\u0930\u0928\u0947 \u0915\u0940 \u0938\u094d\u0925\u093f\u0924\u093f", //no i18n
	"crm.smartfilter.related.module.msg" : "\u0906\u092a \u0924\u0940\u0928 \u0938\u0947 \u0905\u0927\u093f\u0915 \u0938\u0902\u092c\u0902\u0927\u093f\u0924 \u092e\u0949\u0921\u094d\u092f\u0942\u0932\u094d\u0938 \u0928\u0939\u0940\u0902 \u091a\u0941\u0928 \u0938\u0915\u0924\u0947 \u0939\u0948\u0902\u0964" , //no i18n
	"crm.smartfilter.related.module.msg1" : "(\u0909\u0926\u093e\u0939\u0930\u0923: \u0908\u092e\u0947\u0932, \u0917\u0924\u093f\u0935\u093f\u0927\u093f\u092f\u093e\u0901, \u0928\u094b\u091f\u094d\u0938)", //no i18n
	"crm.smartfilter.related.module.msg2" : "\u0905\u0935\u0927\u093f \u0930\u093f\u0915\u094d\u0924 \u0928\u0939\u0940\u0902 \u0939\u094b \u0938\u0915\u0924\u0940", //no i18n
	"crm.label.timeZone": "\u091f\u093e\u0907\u092e \u091c\u094b\u0928", //NO I18n
	"crm.label.insufficient.privileges": "\u092f\u0939 \u0915\u093e\u0930\u094d\u0930\u0935\u093e\u0908 \u0928\u093f\u0937\u094d\u092a\u093e\u0926\u093f\u0924 \u0915\u0930\u0928\u0947 \u0915\u0947 \u0932\u093f\u090f \u0905\u092a\u0930\u094d\u092f\u093e\u092a\u094d\u0924 \u0935\u093f\u0936\u0947\u0937\u093e\u0927\u093f\u0915\u093e\u0930\u0964 \u0905\u092a\u0928\u0947 \u0935\u094d\u092f\u0935\u0938\u094d\u0925\u093e\u092a\u0915 \u0938\u0947 \u0938\u0902\u092a\u0930\u094d\u0915 \u0915\u0930\u0947\u0902\u0964", //NO I18n
	"crm.filter.header.secton.system": "\u0938\u093f\u0938\u094d\u091f\u092e \u092a\u0930\u093f\u092d\u093e\u0937\u093f\u0924 \u092b\u093f\u0932\u094d\u091f\u0930\u094d\u0938",//NO I18N
	"crm.filter.header.secton.fields": "\u092b\u0940\u0932\u094d\u0921\u094d\u0938 \u0915\u0947 \u0905\u0928\u0941\u0938\u093e\u0930 \u092b\u093f\u0932\u094d\u091f\u0930 \u0915\u0930\u0947\u0902",//NO I18N
	"crm.createfield.calcinfo.new" : "\u092f\u0939 \u092b\u0940\u0932\u094d\u0921 \u0906\u092a\u0915\u0947 \u0926\u094d\u0935\u093e\u0930\u093e \u0907\u0928\u092a\u0941\u091f \u0915\u093f\u090f \u0917\u090f \u0915\u093f\u0938\u0940 \u092d\u0940 \u0935\u094d\u092f\u0902\u091c\u0915 \u0915\u0947 \u0932\u093f\u090f \u0915\u0948\u0932\u0915\u094d\u092f\u0942\u0932\u0947\u091f\u0930 \u0915\u0947 \u0930\u0942\u092a \u092e\u0947\u0902 \u0915\u093e\u0930\u094d\u092f \u0915\u0930\u0924\u093e \u0939\u0948\u0964</br> <b>\u0909\u0926\u093e\u0939\u0930\u0923 \u0915\u0947 \u0932\u093f\u090f, 20+20</b> \u0938\u094d\u0935\u091a\u093e\u0932\u093f\u0924 \u0930\u0942\u092a \u0938\u0947 <b>40</b> \u092a\u0930\u093f\u0923\u093e\u092e \u0926\u0947\u0917\u093e",//No i18n
	"crm.lable.read.only" : "\u0930\u0940\u0921 \u0913\u0928\u0932\u0940 \u092b\u0940\u0932\u094d\u0921",//No i18n
	"crm.column.sort.asc" : "\u0906\u0930\u094b\u0939\u0940 \u0915\u094d\u0930\u092e",//No I18n
	"crm.column.sort.desc" : "\u0905\u0935\u0930\u094b\u0939\u0940 \u0915\u094d\u0930\u092e",//No i18n
	"crm.column.unsort" : "\u0905\u0928\u0938\u094b\u0930\u094d\u091f",//No I18n
	"custmr.prtl.notes.shr.to.custmr": "\u0917\u094d\u0930\u093e\u0939\u0915 \u0938\u0947 \u0938\u093e\u091d\u093e \u0915\u0930\u0947\u0902", //NO I18N
	"crm.label.edited": "Edited", //NO I18N
	"crm.label.edited.on": "Edited on", //NO I18N
	"crm.message.limit.exceed": "{1} \u0915\u0947 \u0932\u093f\u090f \u0915\u0947\u0935\u0932 {0} \u0905\u0915\u094d\u0937\u0930\u094b\u0902 \u0915\u0940 \u0905\u0928\u0941\u092e\u0924\u093f \u0939\u0948\u0964", //NO I18N
	"custmr.prtl.notes.shrd.with.custmr": "\u0917\u094d\u0930\u093e\u0939\u0915 \u0938\u0947 \u0938\u093e\u091d\u093e \u0915\u093f\u092f\u093e \u0917\u092f\u093e", //NO I18N
	"crm.button.ok" : "\u0913\u0915\u0947", //NO I18N
	"crm.role.already.selected" :  "\u0907\u0938 \u092d\u0942\u092e\u093f\u0915\u093e \u0915\u093e \u092a\u0939\u0932\u0947 \u0938\u0947 \u0939\u0940 \u091a\u092f\u0928 \u0915\u0930 \u0932\u093f\u092f\u093e \u0917\u092f\u093e \u0939\u0948", //no i18n
	"crm.user.deleted": "\u0909\u092a\u092f\u094b\u0917\u0915\u0930\u094d\u0924\u093e \u0915\u094b \u0939\u091f\u093e \u0926\u093f\u092f\u093e \u0917\u092f\u093e \u0939\u0948",  //NO I18N
	"crm.account.closed": "\u092f\u0939 \u0916\u093e\u0924\u093e \u092c\u0902\u0926 \u0915\u0930 \u0926\u093f\u092f\u093e \u0917\u092f\u093e \u0939\u0948",  //NO I18N
	"crm.start.chat": "\u091a\u0948\u091f \u092a\u094d\u0930\u093e\u0930\u0902\u092d \u0915\u0930\u0947\u0902",  //NO I18N
	"crm.start.call": "\u0915\u094b\u0908 \u0915\u0949\u0932 \u092a\u094d\u0930\u093e\u0930\u0902\u092d \u0915\u0930\u0947\u0902",  //NO I18N
	"crm.recipient.invalid.email" : "\u0905\u092e\u093e\u0928\u094d\u092f \u0908\u092e\u0947\u0932\u094d\u0938 \u092a\u093e\u090f \u0917\u090f\u0964", //NO I18N
	"crm.recipient.add.recipient" : "\u0905\u0924\u093f\u0930\u093f\u0915\u094d\u0924 \u092a\u094d\u0930\u093e\u092a\u094d\u0924\u0915\u0930\u094d\u0924\u093e \u091c\u094b\u0921\u093c\u0947\u0902", //NO I18N
	"crm.start.video.call": "\u0915\u094b\u0908 \u0935\u0940\u0921\u093f\u092f\u094b \u0915\u0949\u0932 \u092a\u094d\u0930\u093e\u0930\u0902\u092d \u0915\u0930\u0947\u0902",  //NO I18N //ignorei18n_start

	"Score":"\u0938\u094d\u0915\u094b\u0930",
	"Positive Score":"\u0927\u0928\u093e\u0924\u094d\u092e\u0915 \u0938\u094d\u0915\u094b\u0930",
	"Negative Score":"\u090b\u0923\u093e\u0924\u094d\u092e\u0915 \u0938\u094d\u0915\u094b\u0930",
	"Touch Point Score":"\u0938\u094d\u092a\u0930\u094d\u0936 \u092a\u0949\u0907\u0902\u091f \u0938\u094d\u0915\u094b\u0930",
	"Positive Touch Point Score":"\u0927\u0928\u093e\u0924\u094d\u092e\u0915 \u0938\u094d\u092a\u0930\u094d\u0936 \u092a\u0949\u0907\u0902\u091f \u0938\u094d\u0915\u094b\u0930",
	"Negative Touch Point Score":"\u090b\u0923\u093e\u0924\u094d\u092e\u0915 \u0938\u094d\u092a\u0930\u094d\u0936 \u092a\u0949\u0907\u0902\u091f \u0938\u094d\u0915\u094b\u0930",
	"crm.label.scoring.rules":"\u0938\u094d\u0915\u094b\u0930\u093f\u0902\u0917 \u0928\u093f\u092f\u092e",
	"is\ OPEN":"is OPEN",//no i18n
	"is\ WON":"is WON",//no i18n
	"is\ LOST":"is LOST",//no i18n
	"crm.potential.all.open":"All Open stages",//no i18n
	"crm.potential.all.won":"All Closed Won stages",//no i18n
	"crm.potential.all.lost":"All Closed Lost stages",//no i18n

	"crm.label.type.minutes": "Type here in minutes", //NO I18N
	"crm.campaign.member.status" : "\u0938\u0926\u0938\u094d\u092f \u0938\u094d\u0924\u0930",//no i18n
	"crm.dashboard.select.type" : "{0} \u0915\u093e \u091a\u092f\u0928 \u0915\u0930\u0947\u0902",//no i18n
	"crm.campaign.service.status":"\u0938\u0947\u0935\u093e \u0938\u094d\u0925\u093f\u0924\u093f",//no i18n

	"crm.label.addColumn":"\u0915\u0949\u0932\u092e \u091c\u094b\u0921\u093c\u0947\u0902",//no i18n
	"crm.button.clear.filter":"\u092b\u093c\u093f\u0932\u094d\u091f\u0930 \u092c\u0902\u0926 \u0915\u0930\u0947\u0902",//no i18n
	"crm.button.show.filter":"\u092b\u093c\u093f\u0932\u094d\u091f\u0930 \u0926\u093f\u0916\u093e\u090f\u0901",//no i18n
	"crm.las.error.user.maxlimit":"\u0906\u092a \u0905\u0927\u093f\u0915\u0924\u092e \u0915\u0947\u0935\u0932 20 \u0909\u092a\u092f\u094b\u0917\u0915\u0930\u094d\u0924\u093e\u0913\u0902 \u0915\u093e \u091a\u092f\u0928 \u0915\u0930 \u0938\u0915\u0924\u0947 \u0939\u0948\u0902\u0964",//no i18n
	"crm.las.error.picklist.maxlimit":"\u0906\u092a \u0905\u0927\u093f\u0915\u0924\u092e \u0915\u0947\u0935\u0932 20 \u0935\u093f\u0915\u0932\u094d\u092a\u094b\u0902 \u0915\u093e \u091a\u092f\u0928 \u0915\u0930 \u0938\u0915\u0924\u0947 \u0939\u0948\u0902\u0964",//no i18n

	"crm.fileuploader.message.responseerror": "\u0905\u092a\u0932\u094b\u0921 \u0935\u093f\u092b\u0932 \u0930\u0939\u093e", //NO I18N
	"crm.storage.create.error":"\u0915\u094b\u0908 \u0928\u090f \u0930\u093f\u0915\u0949\u0930\u094d\u0921\u094d\u0938 \u0928\u0939\u0940\u0902 \u092c\u0928\u093e\u090f \u091c\u093e \u0938\u0915\u0924\u0947 \u0915\u094d\u092f\u094b\u0902\u0915\u093f \u0906\u092a \u0905\u092a\u0928\u0940 \u0905\u0927\u093f\u0915\u0924\u092e \u0921\u0947\u091f\u093e \u0938\u0902\u0917\u094d\u0930\u0939\u0923 \u0938\u0940\u092e\u093e \u0924\u0915 \u092a\u0939\u0941\u0901\u091a \u091a\u0941\u0915\u0947 \u0939\u0948\u0902\u0964",//no i18n
	"crm.storage.create.error.client":"\u0915\u094b\u0908 \u0928\u090f \u0930\u093f\u0915\u0949\u0930\u094d\u0921\u094d\u0938 \u0928\u0939\u0940\u0902 \u092c\u0928\u093e\u090f \u091c\u093e \u0938\u0915\u0924\u0947 \u0915\u094d\u092f\u094b\u0902\u0915\u093f \u0906\u092a\u0915\u093e \u0935\u094d\u092f\u0935\u0938\u094d\u0925\u093e\u092a\u0915 \u0905\u092a\u0928\u0940 \u0905\u0927\u093f\u0915\u0924\u092e \u0921\u0947\u091f\u093e \u0938\u0902\u0917\u094d\u0930\u0939\u0923 \u0938\u0940\u092e\u093e \u0924\u0915 \u092a\u0939\u0941\u0901\u091a \u091a\u0941\u0915\u093e \u0939\u0948\u0964 \u0907\u0938 \u092e\u0941\u0926\u094d\u0926\u0947 \u0915\u093e \u0938\u092e\u093e\u0927\u093e\u0928 \u0915\u0930\u0928\u0947 \u0915\u0947 \u0932\u093f\u090f {0} \u0938\u0947 \u0938\u0902\u092a\u0930\u094d\u0915 \u0915\u0930\u0947\u0902\u0964",//no i18n
	"crm.storage.avail.info":"( {1} \u0915\u093e {0} \u0936\u0947\u0937)",//no i18n
	"crm.storage.error.key.manage":"\u0905\u092a\u0928\u0947 \u0921\u0947\u091f\u093e \u0938\u0902\u0917\u094d\u0930\u0939\u0923 \u0915\u094b \u092a\u094d\u0930\u092c\u0902\u0927\u093f\u0924 \u0915\u0930\u0947\u0902",//no i18n
	"Records":"\u0930\u093f\u0915\u0949\u0930\u094d\u0921\u094d\u0938",//no i18n
	"crm.workflow.alert.additional.recipients" : "\u0905\u0924\u093f\u0930\u093f\u0915\u094d\u0924 \u092a\u094d\u0930\u093e\u092a\u094d\u0924\u0915\u0930\u094d\u0924\u093e", //NO I18N
	"crm.workflow.alert.type.otherEmails" : "\u090f\u0915 \u0908\u092e\u0947\u0932 \u090f\u0921\u094d\u0930\u0947\u0938 \u0938\u0947 \u0905\u0927\u093f\u0915 \u0915\u094b \u0905\u0932\u0917-\u0905\u0932\u0917 \u0915\u0930\u0928\u0947 \u0915\u0947 \u0932\u093f\u090f \u0915\u0949\u092e\u093e \u0915\u093e \u092a\u094d\u0930\u092f\u094b\u0917 \u0915\u0930\u0947\u0902", //NO I18N
	"crm.related.contact.account" : "{0} \u0938\u0947 \u0938\u0902\u092c\u0902\u0927\u093f\u0924 {1}",//No I18n
	"crm.allcontact.show" : "\u0938\u092d\u0940 {0}",//No I18n
	"crm.button.mass.show" : "\u092a\u094d\u0930\u0926\u0930\u094d\u0936\u0928",//No I18n
	"crm.msg.custom.view.not.replied" : "Not Replied Messages", //NO I18N
	"crm.msg.custom.view.replied" : "Replied Messages",//NO I18N
	"crm.workflow.select.recipients" : "\u092a\u094d\u0930\u093e\u092a\u094d\u0924\u0915\u0930\u094d\u0924\u093e", //NO I18N
	"crm.custom.module.no.profile.selected.alert1":"\u0915\u0943\u092a\u092f\u093e \u0915\u092e \u0938\u0947 \u0915\u092e \u090f\u0915 \u092a\u094d\u0930\u094b\u092b\u093e\u0907\u0932 \u091a\u0941\u0928\u0947\u0902.",//NO I18N
	"crm.auto.enrich.remove.default.profile" : "\u0915\u094d\u0937\u092e\u093e \u0915\u0930\u0947\u0902, \u0932\u0947\u0915\u093f\u0928 \u0906\u092a \u0915\u093f\u0938\u0940 \u0921\u093f\u092b\u0949\u0932\u094d\u091f \u092a\u094d\u0930\u094b\u092b\u093e\u0907\u0932 \u0915\u094b \u0928\u0939\u0940\u0902 \u0928\u093f\u0915\u093e\u0932 \u0938\u0915\u0924\u0947\u0964",//NO I18N
	"crm.inv.label.add.emails" : "\u0908\u092e\u0947\u0932 \u091c\u094b\u0921\u093c\u0947\u0902", //NO I18N
	"crm.prediction.analytics.filter.year":"\u092a\u093f\u091b\u0932\u093e \u0935\u0930\u094d\u0937",//no i18n
	"Previous\ FY":"\u092a\u093f\u091b\u0932\u093e \u0935\u093f\u0924\u094d\u0924\u0940\u092f \u0935\u0930\u094d\u0937",//no i18n
	"Current\ FY":"\u0935\u0930\u094d\u0924\u092e\u093e\u0928 \u0935\u093f\u0924\u094d\u0924\u0940\u092f \u0935\u0930\u094d\u0937",//no i18n
	"Next\ FY":"\u0905\u0917\u0932\u093e \u0935\u093f\u0924\u094d\u0924\u0940\u092f \u0935\u0930\u094d\u0937",//no i18n
	"Current\ FQ":"\u0935\u0930\u094d\u0924\u092e\u093e\u0928 \u0935\u093f\u0924\u094d\u0924\u0940\u092f \u0935\u0930\u094d\u0937",//no i18n
	"Next\ FQ":"\u0905\u0917\u0932\u093e \u0935\u093f\u0924\u094d\u0924\u0940\u092f \u0935\u0930\u094d\u0937",//no i18n
	"Previous\ FQ":"\u092a\u093f\u091b\u0932\u0940 \u0935\u093f\u0924\u094d\u0924\u0940\u092f \u0924\u093f\u092e\u093e\u0939\u0940",//no i18n
	"crm.inv.label.add.emails" : "\u0908\u092e\u0947\u0932 \u091c\u094b\u0921\u093c\u0947\u0902" ,//NO I18N
	"crm.picklist.sample.text":"\u0928\u092e\u0942\u0928\u093e \u092a\u093e\u0920",//no i18n
	"crm.more.colors":"\u0905\u0927\u093f\u0915 \u0930\u0902\u0917",//no i18n
	"crm.button.back.alone":"\u092a\u0940\u091b\u0947",//no i18n
	"crm.field.label.email":"\u0908\u092e\u0947\u0932",//no i18n
"crm.zia.nba.feature.label":"\u0905\u0917\u0932\u0940 \u0938\u0930\u094d\u0935\u094b\u0924\u094d\u0924\u092e \u0915\u093e\u0930\u094d\u0930\u0935\u093e\u0908",//no i18n
"Meeting":"\u092c\u0948\u0920\u0915",//no i18n
"Tomorrow":"\u0915\u0932",//no i18n
"crm.gdpr.notavailable.field":"\u0909\u092a\u0932\u092c\u094d\u0927 \u0928\u0939\u0940\u0902",//no i18n
	"crm.setup.system.ziasimilarity":"\u0938\u092e\u093e\u0928\u0924\u093e \u0905\u0928\u0941\u0936\u0902\u0938\u093e",//no i18n
	"crm.gdpr.notavailable.field":"\u0909\u092a\u0932\u092c\u094d\u0927 \u0928\u0939\u0940\u0902",//no i18n
	"crm.filter.label.all.products":"\u0938\u092d\u0940 {0}",//NO I18N
	'crm.zia.nbx.filter.due' : "Due" ,//NO I18N
	"abm.segment": "Segment", // NO I18N
	"abm.segment.names": "Segment Names", // NO I18N
	"abm.abm.segment.name": "ABM Segment Name", // NO I18N
	"abm.segmentation.techniques": "ABM Segmentation Techniques", // NO I18N
	"abm.filter.by.abm.fields": "Filter By ABM Fields", // NO I18N
	"abm.rfm": "RFM", // NO I18N
	"abm.firmographics": "Firmographics", // NO I18N
	"crm.label.no.options.found":"\u0915\u094b\u0908 \u0935\u093f\u0915\u0932\u094d\u092a \u0928\u0939\u0940\u0902 \u092e\u093f\u0932\u093e\u0964",
	"crux.custom.field.greater.than.equalto":"{0} {1} से बड़ा या उसके बराबर होना चाहिए।",
	"crux.users.selected.plural" : "{0} उपयोगकर्ताओं का चयन किया गया।",
	"crux.user.selected.singular" :"{0} उपयोगकर्ता का चयन किया गया।",
	"crux.criteria.empty.secondaryfield.module" : "{1} मॉड्यूल में कोई मैचिंग {0} फील्ड नहीं मिला",
	"crux.criteria.empty.secondaryfield" : "तुलना के लिए कोई अन्य {0} फील्ड उपलब्ध नहीं है, कृपया तुलना करने के लिए मान दर्ज करें।",
	"crux.logged.in.role.definition" : "वह उपयोगकर्ता भूमिका जो रिकॉर्ड कार्रवाइयाँ प्रारंभ करती है",
	"zia.similarity.smartfilter.score":"\u0938\u092e\u093e\u0928\u0924\u093e \u0938\u094d\u0915\u094b\u0930",//no i18n
	"zia.similarity.smartfilter.records":"\u0907\u0938\u0915\u0947 \u0938\u092e\u093e\u0928 {0}:",//no i18n
	"zia.similarity.smartfilter.records.search":"\u0907\u0938\u0915\u0947 \u0938\u092e\u093e\u0928 {0} \u0926\u093f\u0916\u093e\u090f\u0902:",//no i18n
	"crux.smartfilter.multiselect.maximum.selected":"\u0906\u092a {0} {1} \u0938\u0947 \u0905\u0927\u093f\u0915 \u0915\u093e \u091a\u092f\u0928 \u0928\u0939\u0940\u0902 \u0915\u0930 \u0938\u0915\u0924\u0947",//no i18n
	"crm.label.no.options.found":"\u0915\u094b\u0908 \u0935\u093f\u0915\u0932\u094d\u092a \u0928\u0939\u0940\u0902 \u092e\u093f\u0932\u093e\u0964",
	"crux.custom.field.greater.than.equalto":"{0} {1} से बड़ा या उसके बराबर होना चाहिए।",
	"crux.users.selected.plural" : "{0} उपयोगकर्ताओं का चयन किया गया।",
	"crux.user.selected.singular" :"{0} उपयोगकर्ता का चयन किया गया।",
	"crux.criteria.empty.secondaryfield.module" : "{1} मॉड्यूल में कोई मैचिंग {0} फील्ड नहीं मिला",
	"crux.criteria.empty.secondaryfield" : "तुलना के लिए कोई अन्य {0} फील्ड उपलब्ध नहीं है, कृपया तुलना करने के लिए मान दर्ज करें।",
	"crux.logged.in.role.definition" : "वह उपयोगकर्ता भूमिका जो रिकॉर्ड कार्रवाइयाँ प्रारंभ करती है",
	"crux.max.limit.unselect" : "आप {0} {1} से अधिक को अनसलेक्ट नहीं कर सकते।", //NO I18N
	"crux.existing.tag" : "\u201c{0}\u201d का पहले ही चयन कर लिया गया है" //NO I18N

}
